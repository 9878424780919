import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Block from '@mui/icons-material/Block';
import { COMPANY_LEGAL_REPRESENTATIVES } from '../../graphql';
import LoadingIconButton from '../buttons/LoadingIconButton';
import { FingoDialog } from '../dialogs';
import { FingoDataGrid } from '../dataGrids';
import { LegalRepresentativeColumns } from '../../constants';
import { formatDateTime } from '../../helpers';

const CompanyBlacklistComponent = ({ masterEntity, disableDisplay }) => {
  const [open, setOpen] = useState(false);
  const [loadLR, { data, loading }] = useLazyQuery(COMPANY_LEGAL_REPRESENTATIVES, {
    variables: { masterEntityId: masterEntity.id },
    onCompleted: () => {
      setOpen(true);
    },
  });
  const legalRepresentative = data?.getMasterEntity?.company?.legalRepresentative || [];
  if (!masterEntity.riskBlacklist) return <></>;
  return (
    <>
      <Tooltip title="Empresa en la Blacklist">
        <span>
          <LoadingIconButton
            loading={loading}
            sx={{ width: 15, height: 15 }}
            onClick={loadLR}
            disabled={disableDisplay}
          >
            <Block loading sx={{ width: 15, height: 15 }} color="error" />
          </LoadingIconButton>
        </span>
      </Tooltip>
      <FingoDialog
        open={open}
        title={`Blacklist ${masterEntity.name}`}
        subtitle={`Creación: ${formatDateTime(moment(masterEntity.riskBlacklist?.createdAt))}`}
        handleClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <Stack spacing={1} direction="column">
          <Typography variant="h4">Comentarios</Typography>
          <TextField
            variant="outlined"
            fullWidth
            minRows={3}
            multiline
            value={masterEntity.riskBlacklist?.comments || 'Sin comentarios asociados'}
            disabled
          />
          <Typography variant="h4">Composición Sociedad</Typography>
          <FingoDataGrid
            rows={legalRepresentative}
            columns={LegalRepresentativeColumns.filter((col) => !['dicomDetails'].includes(col.field))}
            hideFooter
          />
        </Stack>
      </FingoDialog>
    </>
  );
};

CompanyBlacklistComponent.propTypes = {
  disableDisplay: PropTypes.bool,
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    riskBlacklist: PropTypes.shape({
      id: PropTypes.string.isRequired,
      createdAt: PropTypes.string,
      comments: PropTypes.string,
    }),
  }).isRequired,
};

CompanyBlacklistComponent.defaultProps = {
  disableDisplay: false,
};
export default CompanyBlacklistComponent;
