import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { CompanyDetailsButton } from '../buttons';
import CompanyBlacklistComponent from './CompanyBlacklistComponent';
import CompanyDebtsSummary from './CompanyDebtsSummary';

const CompanyCell = ({ company, showDetails, showBlacklist, type }) => {
  const { id, name, displayNationalIdentifier } = company;
  return (
    <Grid width="100%" alignItems="flex-start" justifyContent="center">
      <Stack direction="row">
        <Typography variant="body2" width="inherit" noWrap>
          {name}
        </Typography>
        {showBlacklist && (
        <CompanyBlacklistComponent
          masterEntity={company}
          disableDisplay
        />
        )}
        <CompanyDebtsSummary masterEntity={company} type={type} />
      </Stack>
      <Typography variant="caption" width="inherit" noWrap>
        {displayNationalIdentifier}
      </Typography>
      {showDetails && <CompanyDetailsButton companyId={id} />}
    </Grid>
  );
};

CompanyCell.propTypes = {
  type: PropTypes.oneOf(['company', 'receiver']),
  showBlacklist: PropTypes.bool,
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    displayNationalIdentifier: PropTypes.string.isRequired,
    riskBlacklist: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  showDetails: PropTypes.bool,
};

CompanyCell.defaultProps = {
  type: 'company',
  showBlacklist: false,
  showDetails: false,
};

export default CompanyCell;
