import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReportProblem from '@mui/icons-material/ReportProblem';
import PriorityHigh from '@mui/icons-material/PriorityHigh';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import theme from '../../theme';

const CompanyDebtsSummary = ({ masterEntity, disabled, type }) => {
  const reintegrations = useMemo(() => {
    if (type === 'company') return masterEntity?.company?.pendingReintegrations ?? null;
    return masterEntity?.pendingReintegrations ?? null;
  }, [type]);
  const replacements = useMemo(() => {
    if (type === 'company') return masterEntity?.company?.pendingReplacements ?? null;
    return masterEntity?.pendingReplacements ?? null;
  }, [type]);
  const debts = useMemo(() => {
    if (type === 'company') return masterEntity?.company?.debtInvoices ?? null;
    return masterEntity?.debtInvoices ?? null;
  }, [type]);
  const debtsOrdering = useMemo(() => {
    if (type === 'company') return masterEntity?.company?.debtOrdering ?? null;
    return masterEntity?.debtOrdering ?? null;
  }, [type]);
  const schedules = masterEntity?.debtAmortizationSchedule ?? null;
  const rowDetail = (detail, label) => {
    if (!detail) return <></>;
    return (
      <Stack direction="row">
        <PriorityHigh sx={{ width: 15, height: 15 }} color="error" />
        <Typography>
          {label}: {detail?.totalAmount?.chileFormat} ({detail?.count})
        </Typography>
      </Stack>
    );
  };
  const tootipText = useMemo(() => (
    <>
      {rowDetail(reintegrations, 'Reintegros Pendientes')}
      {rowDetail(replacements, 'Reemplazos Pendientes')}
      {rowDetail(debts, 'Facturas en Mora')}
      {rowDetail(debtsOrdering, 'Ordering en Mora')}
      {rowDetail(schedules, 'Planes de Pago Pendientes')}
    </>
  ), [reintegrations, debts, debtsOrdering, schedules]);
  if (
    (!reintegrations && !replacements && !debts && !debtsOrdering && !schedules)
    || disabled
  ) return <></>;

  const iconColor = useMemo(() => {
    if (debtsOrdering?.count > 0 || schedules?.count > 0) return theme.palette.error.main;
    return theme.palette.warning.main;
  }, [reintegrations, debts, debtsOrdering, schedules]);

  return (
    <Tooltip title={tootipText}>
      <ReportProblem sx={{ width: 15, paddingBottom: 1, color: iconColor }} />
    </Tooltip>
  );
};

CompanyDebtsSummary.propTypes = {
  type: PropTypes.oneOf(['company', 'receiver']),
  disabled: PropTypes.bool,
  masterEntity: PropTypes.shape({
    pendingReintegrations: PropTypes.shape({
      count: PropTypes.number,
      totalAmount: PropTypes.shape({
        chileFormat: PropTypes.string,
      }),
    }),
    debtInvoices: PropTypes.shape({
      count: PropTypes.number,
      totalAmount: PropTypes.shape({
        chileFormat: PropTypes.string,
      }),
    }),
    debtOrdering: PropTypes.shape({
      count: PropTypes.number,
      totalAmount: PropTypes.shape({
        chileFormat: PropTypes.string,
      }),
    }),
    pendingReplacements: PropTypes.shape({
      count: PropTypes.number,
      totalAmount: PropTypes.shape({
        chileFormat: PropTypes.string,
      }),
    }),
    company: PropTypes.shape({
      pendingReintegrations: PropTypes.shape({
        count: PropTypes.number,
        totalAmount: PropTypes.shape({
          chileFormat: PropTypes.string,
        }),
      }),
      debtInvoices: PropTypes.shape({
        count: PropTypes.number,
        totalAmount: PropTypes.shape({
          chileFormat: PropTypes.string,
        }),
      }),
      debtOrdering: PropTypes.shape({
        count: PropTypes.number,
        totalAmount: PropTypes.shape({
          chileFormat: PropTypes.string,
        }),
      }),
      pendingReplacements: PropTypes.shape({
        count: PropTypes.number,
        totalAmount: PropTypes.shape({
          chileFormat: PropTypes.string,
        }),
      }),
    }).isRequired,
    debtAmortizationSchedule: PropTypes.shape({
      count: PropTypes.number,
      totalAmount: PropTypes.shape({
        chileFormat: PropTypes.string,
      }),
    }),
  }).isRequired,
};

CompanyDebtsSummary.defaultProps = {
  type: 'company',
  disabled: false,
};

export default CompanyDebtsSummary;
